import React, { useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { Text } from '@pancakeswap-libs/uikit'

export const Heading = styled.div<{ topMargin?: boolean }>`
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  text-shadow: 1px 1px 10px #fff, 1px 1px 10px #ccc;
  margin-bottom: 10px;
  margin-top: ${(props) => (props.topMargin ? '30px' : '0')};
  color: black;
  filter: drop-shadow(3px 1px 1px white);
`
export const SubHeading = styled(Heading)<{ left?: boolean }>`
  font-size: 25px;
  margin: 5px;
  ${({ left }) => left && 'text-align: left;'}
`

export const TextHeading = styled(Text)`
  margin: 10px 0px 0px 0px;
  font-weight: 600;
  font-size: 16px;
`

export const TextBody = styled(Text)`
  font-size: 14px;
`

export default {
  TextHeading,
  TextBody,
  Heading,
  SubHeading,
}
