import React, { useState, useEffect } from 'react'
import { Text } from '@pancakeswap-libs/uikit'

const CountdownTimer = ({
  endTime,
  timeNow,
}: {
  endTime: Date
  timeNow?: Date
}) => {
  const [now, setNow] = useState(new Date())
  useEffect(() => {
    let intervalId
    if (!timeNow) {
      intervalId = setInterval(() => {
        const n = new Date()
        setNow(n)
      }, 1000)
    }
    return () => {
      clearInterval(intervalId)
    }
  }, [endTime, timeNow])
  const timeDifference =
    endTime.getTime() - (timeNow ? timeNow?.getTime() : now.getTime())
  if (timeDifference < 0) {
    return <Text>00:00:00</Text>
  }
  let msec = timeDifference
  const hh = Math.floor(msec / 1000 / 60 / 60)
  msec -= hh * 1000 * 60 * 60
  const mm = Math.floor(msec / 1000 / 60)
  msec -= mm * 1000 * 60
  const ss = Math.floor(msec / 1000)
  msec -= ss * 1000

  const formatedDate = `${`0${hh}`.slice(-2)}:${`0${mm}`.slice(
    -2
  )}:${`0${ss}`.slice(-2)}`
  return <Text>{formatedDate}</Text>
}

export default CountdownTimer
