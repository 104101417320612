import React, { useCallback, useState } from 'react'
import { Input, Button, Modal, Flex, Text } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import ModalActions from 'components/ModalActions'
import Select from 'react-select'
import { HashRouter as Router } from 'react-router-dom'
import queryString from 'query-string'

interface FilterModalProps {
  onDismiss: () => void
}

const CustomButton = styled(Button)`
  background-color: ${(props) =>
    props.variant === 'secondary' ? 'white' : 'rgb(29, 162, 231)'};
  color: ${(props) =>
    props.variant === 'secondary' ? 'rgb(29, 162, 231)' : 'white'};
  border: ${(props) =>
    props.variant === 'secondary' ? '1px solid rgb(29, 162, 231)' : 'none'};
`
const Field = styled.div`
  align-items: center;
  & > ${Input} {
    max-width: 100px;
  }
`

const selectThemeColors = {
  neutral90: 'hsl(0, 0%, 100%)',
  neutral80: 'hsl(0, 0%, 95%)',
  neutral70: 'hsl(0, 0%, 90%)',
  neutral60: 'hsl(0, 0%, 80%)',
  neutral50: 'hsl(0, 0%, 70%)',
  neutral40: 'hsl(0, 0%, 60%)',
  neutral30: 'hsl(0, 0%, 50%)',
  neutral20: 'hsl(0, 0%, 40%)',
  neutral10: 'hsl(0, 0%, 30%)',
  neutral5: 'hsl(0, 0%, 20%)',
  neutral0: 'hsl(0, 0%, 10%)',
}

const sortOptions = [
  { value: 'priceAsc', label: 'Price: Low to High' },
  { value: 'priceDesc', label: 'Price: High to Low' },
  { value: 'timeEndAsc', label: 'Ending: Soonest' },
  { value: 'timeEndDesc', label: 'Ending: Latest' },
]

const FilterModal: React.FC<FilterModalProps> = ({ onDismiss }) => {
  return (
    <Modal title="Filter Results" onDismiss={onDismiss}>
      <Router>
        <FilterContent onDismiss={onDismiss} />
      </Router>
    </Modal>
  )
}

// @ts-ignore
const FilterContent: React.FC<FilterModalProps> = ({ onDismiss }) => {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const { priceMin, priceMax, sort } = queryString.parse(search)


  const [minPrice, setMinPrice] = useState(priceMin || '')
  const [maxPrice, setMaxPrice] = useState(priceMax || '')
  const [sortVal, setSortVal] = useState(
    sort ? sortOptions.find((op) => op.value === sort) : ''
  )

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget.validity.valid) {
        if (e.currentTarget.id === 'minimum') {
          setMinPrice(e.currentTarget.value.replace(/,/g, '.'))
        } else {
          setMaxPrice(e.currentTarget.value.replace(/,/g, '.'))
        }
      }
    },
    [setMinPrice, setMaxPrice]
  )

  const handleSelect = useCallback(
    (sortValue: any) => {
      setSortVal(sortValue)
    },
    [setSortVal]
  )

  const onConfirm = () => {
    const searchQuery = queryString.stringify({
      priceMin: minPrice,
      priceMax: maxPrice,
      sort: (sortVal as any).value,
    })
    history.replace({
      search: searchQuery,
    })
    onDismiss()
  }
  return (
    <>
      <Text bold mb="4px">
        Sort By
      </Text>
      <div style={{ color: 'rgb(200,200,200)' }}>
        <Select
          value={sortVal}
          onChange={handleSelect}
          // @ts-ignore
          options={sortOptions}
          theme={(theme) => {
            return {
              ...theme,
              colors: {
                ...theme.colors,
                ...selectThemeColors,
              },
            }
          }}
        />
      </div>
      <br />
      <Text bold mb="4px">
        Price
      </Text>
      <Flex>
        <Field>
          <Text fontSize="14px" ml="8px">
            Min Price
          </Text>
          <Input
            id="minimum"
            type="number"
            step="1"
            min="0"
            value={minPrice}
            onChange={handleChange}
          />
        </Field>
        <Text fontSize="14px" style={{ padding: '30px 20px 0px 20px' }}>
          To
        </Text>
        <Field>
          <Text fontSize="14px" ml="8px">
            Max Price
          </Text>
          <Input
            id="maximum"
            type="number"
            step="1"
            min="0"
            value={maxPrice}
            onChange={handleChange}
          />
        </Field>
      </Flex>
      <ModalActions>
        <CustomButton variant="secondary" onClick={onDismiss} width="100%">
          Cancel
        </CustomButton>
        <CustomButton
          width="100%"
          onClick={() => {
            onConfirm()
          }}
        >
          Confirm
        </CustomButton>
      </ModalActions>
    </>
  )
}

export default FilterModal