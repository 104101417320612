import React, { useState } from 'react'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import { light, dark } from '@pancakeswap-libs/uikit'

const CACHE_KEY = 'IS_DARK'

export interface ThemeContextType {
  isDark: boolean
  toggleTheme: () => void
}

const ThemeContext = React.createContext<ThemeContextType>({ isDark: true, toggleTheme: () => null })

const ThemeContextProvider: React.FC = ({ children }) => {
  const [isDark, setIsDark] = useState(() => {
    const isDarkUserSetting = localStorage.getItem(CACHE_KEY)
    return isDarkUserSetting ? JSON.parse(isDarkUserSetting) : false
  })

  const toggleTheme = () => {
    setIsDark((prevState: any) => {
      localStorage.setItem(CACHE_KEY, JSON.stringify(!prevState))
      return !prevState
    })
  }
  const colorOverride =
    // Change everything up to and inc. textSubtle or secondary
    {
      failure: '#ED4B9E',
      primary: '#F47A08',
      primaryBright: '#FFA14B',
      primaryDark: '#A35105',
      secondary: '#FBEB15',
      success: '#31D0AA',
      warning: '#FFB237',
      binance: '#F0B90B',
      background: '#100C18',
      backgroundDisabled: '#54454B',
      backgroundAlt: '#403B36',
      contrast: '#FFFFFF',
      dropdown: '#1D1915',
      invertedContrast: '#191326',
      input: '#401F00',
      inputSecondary: '#602F00',
      tertiary: '#2D2620',
      text: '#F8D8B3',
      textDisabled: '#858380',
      textSubtle: '#E3BD90',
      borderColor: '#F47A08',
      overlay: '#000000',
      gradients: {
        bubblegum: 'linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%)',
        cardHeader: 'linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)',
        blue: 'linear-gradient(180deg, #00707F 0%, #19778C 100%)',
        violet: 'linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)',
        violetAlt: 'linear-gradient(180deg, #434575 0%, #66578D 100%)',
      },
    }

  return (
    <ThemeContext.Provider value={{ isDark: true, toggleTheme }}>
      <SCThemeProvider theme={{ ...dark, colors: colorOverride }}>{children}</SCThemeProvider>
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeContextProvider }
