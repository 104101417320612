import React, { ReactNode } from 'react'
import { useActiveWeb3React } from 'hooks'
import styled from 'styled-components'
import {
  Heading,
  IconButton,
  Text,
  Flex,
  useModal,
  TuneIcon,
  HistoryIcon,
} from '@pancakeswap-libs/uikit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWallet, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import { ArrowLeft } from 'react-feather'

interface PageHeaderProps {
  title: ReactNode
  description?: ReactNode
  children?: ReactNode
  showBack?: boolean
}

const StyledPageHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 24px;
`

const Details = styled.div`
  flex: 1;
  text-align: center;
`

const PointerDiv = styled.div`
  cursor: pointer;
`

const WalletButton = styled(IconButton)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60px;
  padding: 2px 10px;
`
const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.colors.text};
  fill: none !important;
`

const NftCardHeader = ({
  title,
  description,
  children,
  showBack,
}: PageHeaderProps) => {
  const { account } = useActiveWeb3React()
  const history = useHistory()
  return (
    <StyledPageHeader>
      <Flex alignItems="center">
        {showBack && (
          <PointerDiv
            onClick={() => {
              if (history.length > 2) {
                history.goBack()
              } else {
                history.push('/mintnfts')
              }
            }}
          >
            <StyledArrowLeft />
          </PointerDiv>
        )}
        <Details>
          <Heading mb="8px">{title}</Heading>
          {description && (
            <Text color="textSubtle" fontSize="14px">
              {description}
            </Text>
          )}
        </Details>
        {showBack && <StyledArrowLeft style={{ opacity: 0 }} />}
      </Flex>
      {children && <Text mt="16px">{children}</Text>}
    </StyledPageHeader>
  )
}

export default NftCardHeader
