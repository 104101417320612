import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }

    .colorTheme {
      color: rgb(244, 122, 8) !important;
    }

    .bgTheme {
      background-color: rgb(244, 122, 8) !important;
    }

    .primaryBtn {
      background-color: rgb(244, 122, 8) !important;
      color: white;
    }

    .tertiaryBtn {
      color: rgb(244, 122, 8);
      background-color: #353547;
    }

    #token-search-input:focus {
      border-color: rgb(244, 122, 8);
    }

    a {
      color: rgb(244, 122, 8);
      fill: rgb(244, 122, 8);
    }

    div[color="primary"], div[color="#1FC7D4"],
    a[variant="primary"] {

    }
  }

  ul {
    list-style: none;
  }

  li {
    display: flex;
    align-items: center;
  }

  li::before {
    content: "•";
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 8px;
  }

  .dropdown {
    display: inline-block;
  }

  .dropdown__content {
      display: none;
      position: absolute;
  }

  .dropdown--active .dropdown__content {
      display: block;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(244,122,8)
  }

 /*this is a hack for the Overlay component in pancakeswap/uikit*/
 .sc-bTfYFJ.klmRlF {
  background-color: #000
 }

 .cacVjy:focus:not(:disabled) {
    box-shadow: 0px 0px 0px 1px #FBEB15, 0px 0px 0px 4px #FBEB1599;
 }

`

export default GlobalStyle
