import React, { useState, useMemo, useEffect } from 'react'
import { useActiveWeb3React } from 'hooks'
import { useContract } from 'hooks/useContract'
import { Link } from 'react-router-dom'
import useToast from 'hooks/useToast'
import styled from 'styled-components'
import { ArrowLeft, ArrowRight } from 'react-feather'
import { Card, Button } from '@pancakeswap-libs/uikit'
import { Wrapper } from 'components/swap/styleds'
import Container from 'components/Container'
import CardNav from 'components/CardNav'
import NftCard from 'components/NftCard'
import {
  GlowHeading as Heading,
  GlowSubHeading as SubHeading,
} from 'components/GlowHeading'
import { shortenAccount } from 'utils/formatAccount'
import * as GalaxyHeroesNFT from 'config/abis/GalaxyHeroesNFT.json'
import * as NftMarketplace from 'config/abis/NftMarketplace.json'
import { marketAddressByChain } from 'config'
import { nftAddressByChain } from 'config'
import NftPageHeader from 'components/NftPageHeader'

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.colors.text};
  fill: none !important;
  cursor: pointer;
`

const StyledArrowRight = styled(ArrowRight)`
  color: ${({ theme }) => theme.colors.text};
  fill: none !important;
  cursor: pointer;
`

const PageLabel = styled.span`
  color: white;
  vertical-align: top;
  padding: 0 10px;
`

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  justify-content: center;
`

export const CardWrapper = styled(Card)`
  position: relative;
  @media only screen and (max-width: 780px) {
    width: 100%;
  }
  width: 350px;
  max-width: 100%;
  z-index: 5;
  background-color: rgba(1, 1, 1, 0.6);
  border: 2px solid rgb(244, 122, 8);
  box-shadow: rgb(221 82 0) 0px 0px 1.5rem,
    rgb(221 82 0 / 70%) 0px 0px 1rem 0px inset;
  margin: auto;
  overflow: visible;
`

const FlexElement = styled.div`
  padding: 10px;
  flex: 1;
  justify-content: center;
`

const PER_PAGE = 15

const NftWallet = ({ match: { path } }) => {
  const { account, chainId } = useActiveWeb3React()
  const nftContract = useContract(
    nftAddressByChain[chainId || '56'],
    GalaxyHeroesNFT.abi,
    true
  )
  const marketContract = useContract(
    marketAddressByChain[chainId || '56'],
    NftMarketplace.abi,
    true
  )
  const [loading, setLoading] = useState(true)
  const [tokens, setTokens] = useState([])
  const [renderKey, setRenderKey] = useState('0')
  const { toastSuccess, toastError } = useToast()
  const [accountLabel, setAccountLabel] = useState('')
  const [page, setPage] = useState(1)

  const showListings = path === '/nftmylistings'
  const nullMessage = showListings
    ? 'You have no active listings'
    : 'No King Shiba NFTs in your wallet'

  useEffect(() => {
    const loadData = async () => {
      if (account) {
        setLoading(true)
        try {
          setAccountLabel(shortenAccount(account))
          const tokensOwnedCount = await nftContract?.callStatic.balanceOf(
            account
          )
          if (tokensOwnedCount.toString !== '0') {
            const usersTokens = await nftContract?.callStatic.walletOfOwner(
              account
            )
            setTokens(usersTokens)
          }

          setLoading(false)
        } catch (e) {
          setLoading(false)
          throw e
        }
      }
    }
    loadData()
  }, [
    account,
    chainId,
    nftContract,
    renderKey,
    path,
    marketContract?.callStatic,
  ])
  const displayedTokens =
    tokens.length && tokens.slice((page - 1) * PER_PAGE, page * PER_PAGE)
  const totalPages = Math.ceil(tokens.length / PER_PAGE)
  return (
    <Container>
      <Heading> Your NFTS</Heading>
      <CardNav />
      <CardWrapper style={{ zIndex: 100 }}>
        <Wrapper id="nft-wallet-page">
          <NftPageHeader
            showBack
            title={accountLabel}
            description={accountLabel && 'Your collection of King Shiba NFTs'}
          />
        </Wrapper>
      </CardWrapper>
      <hr />
      <FlexContainer>
        {account ? (
          loading ? (
            'Loading...'
          ) : tokens.length > 0 ? (
            <>
              {displayedTokens.map((t) => (
                <NftCard
                  key={Number(t)}
                  contract={nftContract}
                  id={Number(t)}
                />
              ))}
            </>
          ) : (
            <SubHeading>{nullMessage}</SubHeading>
          )
        ) : (
          <SubHeading topMargin="2em">
            Connect Your Wallet to see NFTs
          </SubHeading>
        )}
      </FlexContainer>
      {tokens.length > PER_PAGE && (
        <div style={{ lineHeight: '25px' }}>
          {page > 1 && (
            <StyledArrowLeft
              onClick={() => {
                setPage(page - 1)
              }}
            />
          )}
          <PageLabel>
            {page} of {totalPages}
          </PageLabel>
          {page < totalPages && (
            <StyledArrowRight
              onClick={() => {
                setPage(page + 1)
              }}
            />
          )}
        </div>
      )}
      <br />
      {showListings ? (
        <Link to="/nftwallet">
          <Button>Create Listing</Button>
        </Link>
      ) : (
        <Link to="/nftmarket">
          <Button>Get NFTs on our Marketplace</Button>
        </Link>
      )}
    </Container>
  )
}

export default NftWallet
