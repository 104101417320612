import React from 'react'
import styled from 'styled-components'

interface Props {
  left?: boolean;
  topMargin?: string;
}

export const GlowHeadingOld= styled.div`
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  text-shadow: 1px 1px 10px #fff, 1px 1px 10px #ccc;
  margin-bottom: 10px;
  margin-top: 30px;
  color: black;
  filter: drop-shadow(3px 1px 1px white);
`

export const GlowHeading = styled.div<Props>`
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  text-shadow: 1px 1px 10px rgb(244 122 8), 1px 1px 10px rgb(221 82 0);
  color: ${props => props.theme.colors.text};
  margin-bottom: 1em;
  margin-top: ${props => props.topMargin ? props.topMargin : '0.4em'};
  filter: drop-shadow(3px 1px 1px rgb(221 82 0));
`

export const GlowSubHeading = styled(GlowHeading)<Props>`
  font-size: 1.5rem;
  ${({ left }) => left && 'text-align: left;'}
`

export default GlowHeadingOld
