import { getNumber } from 'utils/formatBalance'

export default function filterAuctions(
  auctions,
  { priceMin = '', priceMax = '', sort = '' }
) {
  let sortedAucs = auctions.slice()
  if (sort.includes('price')) {
    sortedAucs.sort((a, b) => {
      const aFloorPrice = Math.max(
        getNumber(a.reservePrice),
        getNumber(a.amount)
      )
      const bFloorPrice = Math.max(
        getNumber(b.reservePrice),
        getNumber(b.amount)
      )
      return sort === 'priceAsc'
        ? aFloorPrice - bFloorPrice
        : bFloorPrice - aFloorPrice
    })
  } else if (sort.includes('timeEnd')) {
    sortedAucs.sort((a, b) => {
      const aEndTime =
        new Date(a.firstBidTime * 1000).getTime() + Number(a.duration) * 1000
      const bEndTime =
        new Date(b.firstBidTime * 1000).getTime() + Number(b.duration) * 1000
      return sort === 'timeEndAsc' ? bEndTime - aEndTime : aEndTime - bEndTime
    })
  }
  if (priceMin) {
    sortedAucs = sortedAucs.filter((a) => {
      const aFloorPrice = Math.max(
        getNumber(a.reservePrice),
        getNumber(a.amount)
      )
      return aFloorPrice > Number(priceMin)
    })
  }
  if (priceMax) {
    sortedAucs = sortedAucs.filter((a) => {
      const aFloorPrice = Math.max(
        getNumber(a.reservePrice),
        getNumber(a.amount)
      )
      return aFloorPrice < Number(priceMax)
    })
  }
  return sortedAucs
}