import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { FEATURE_FLAGS } from '../constants'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure
} from './AddLiquidity/redirects'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import AddLiquidity from './AddLiquidity'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import Swap from './Swap'
import Farms from './Farms'
import FarmsV2 from './FarmsV2'
import Mint from './Mint'
import NftWallet from './NftWallet'
import NftMarket from './NftMarket'
import NftPdp from './NftPdp'
import NotFound from './NotFound'
import Migration from './Migration'
import { RedirectPathToHomeOnly } from './Home/redirects'

import EggMenu from '../components/EggMenu'
import useGetDocumentTitlePrice from '../hooks/useGetDocumentTitlePrice'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const BodyWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  margin-bottom: 64px;
  ${({ theme }) => theme.mediaQueries.lg} {
    margin-bottom: 0;
  }
`

const CACHE_KEY = 'pancakeSwapLanguage'

export default function App() {
  const fileId = 6

  useGetDocumentTitlePrice()

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <AppWrapper style={{ overflow: 'hidden' }}>
          <EggMenu>
            <BodyWrapper>
              <Popups />
              <Web3ReactManager>
                <Switch>
                  {/* <Route exact strict path="/home" component={Home} /> */}
                  {/* <Route
                    exact
                    strict
                    path="/swap"
                    component={FEATURE_FLAGS.swap ? Swap : NotFound}
                  />
                  <Route
                    exact
                    strict
                    path="/find"
                    component={FEATURE_FLAGS.pool ? PoolFinder : NotFound}
                  />
                  <Route
                    exact
                    strict
                    path="/pool"
                    component={FEATURE_FLAGS.pool ? Pool : NotFound}
                  />
                  <Route
                    exact
                    path="/migrate"
                    component={FEATURE_FLAGS.migrate ? Migration : NotFound}
                  />
                  <Route
                    exact
                    path="/add"
                    component={
                      FEATURE_FLAGS.liquidity ? AddLiquidity : NotFound
                    }
                  />
                  <Route
                    exact
                    strict
                    path="/remove/:currencyIdA/:currencyIdB"
                    component={
                      FEATURE_FLAGS.liquidity ? RemoveLiquidity : NotFound
                    }
                  />
                  <Route
                    exact
                    strict
                    path="/farmsv1"
                    component={FEATURE_FLAGS.farm ? Farms : NotFound}
                  />
                  <Route
                    exact
                    strict
                    path="/farms"
                    component={FEATURE_FLAGS.farm ? FarmsV2 : NotFound}
                  /> */}
                  <Route exact strict path="/mintnfts" component={Mint} />
                  <Route
                    exact
                    strict
                    path="/nftwallet"
                    component={FEATURE_FLAGS.nft ? NftWallet : NotFound}
                  />
                  
                  <Route
                    exact
                    strict
                    path="/nftmylistings"
                    component={FEATURE_FLAGS.nft ? NftMarket : NotFound}
                  />
                  <Route
                    exact
                    strict
                    path="/nftmarket"
                    component={FEATURE_FLAGS.nft ? NftMarket : NotFound}
                  />
                  <Route
                    exact
                    strict
                    path="/nftpdp/:nftCollectionAddress/:tokenId"
                    component={FEATURE_FLAGS.nft ? NftPdp : NotFound}
                  />
                  {/* Redirection: These old routes are still used in the code base */}
                  {/* <Route
                    exact
                    path="/add/:currencyIdA"
                    component={
                      FEATURE_FLAGS.currency
                        ? RedirectOldAddLiquidityPathStructure
                        : NotFound
                    }
                  />
                  <Route
                    exact
                    path="/add/:currencyIdA/:currencyIdB"
                    component={
                      FEATURE_FLAGS.currency
                        ? RedirectDuplicateTokenIds
                        : NotFound
                    }
                  />
                  <Route
                    exact
                    strict
                    path="/remove/:tokens"
                    component={
                      FEATURE_FLAGS.currency
                        ? RedirectOldRemoveLiquidityPathStructure
                        : NotFound
                    }
                  /> */}

                  <Route
                    exact
                    strict
                    path="/"
                    component={RedirectPathToHomeOnly}
                  />
                  <Route
                    component={
                      FEATURE_FLAGS.redirectHome
                        ? RedirectPathToHomeOnly
                        : NotFound
                    }
                  />
                </Switch>
              </Web3ReactManager>
            </BodyWrapper>
          </EggMenu>
        </AppWrapper>
      </HashRouter>
    </Suspense>
  )
}
