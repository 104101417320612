import React, { useState, useMemo, useEffect } from 'react'
import { useActiveWeb3React } from 'hooks'
import styled from 'styled-components'
import { useContract } from 'hooks/useContract'
import useToast from 'hooks/useToast'
import { CardBody } from '@pancakeswap-libs/uikit'
import { ArrowWrapper, BottomGrouping, Wrapper } from 'components/swap/styleds'
import Container from 'components/Container'
import CardNav from 'components/CardNav'
import CountdownTimer from 'components/CountdownTimer'
import ImageFlasher from 'components/ImageFlasher'
import { AutoColumn } from 'components/Column'
import { GlowHeading} from 'components/GlowHeading'
import * as GalaxyHeroesNFT from 'config/abis/GalaxyHeroesNFT.json'
import { nftAddressByChain } from 'config'
import NftPageHeader from 'components/NftPageHeader'
import AppBody from '../AppBody'

const LAUNCH_TIME = new Date('2021-11-29T20:00:00-00:00')

export const Btn = styled.button`
  border-width: 0px;
  font-size: 16px;
  color: white;
  border-radius: 10px;
  background-color: rgb(244, 122, 8);
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.25s;
  &:hover {
    background-color: rgba(163, 81, 5, 0.8);
  }
  &:disabled {
    background-color: rgba(100, 100, 100, 0.4);
    cursor: none;
  }
`

const MintNfts = () => {
  const { account, chainId } = useActiveWeb3React()
  const nftContract = useContract(
    nftAddressByChain[chainId || '56'],
    GalaxyHeroesNFT.abi,
    true
  )
  const [state, setState] = useState('')
  const [balanceOf, setBalanceOf] = useState('0')
  const [renderKey, setRenderKey] = useState('0')
  const { toastSuccess, toastError } = useToast()
  const [timeNow, setTimeNow] = useState(new Date())
  const [maxElements, setMaxElements] = useState('2500')
  const [totalSupply, setTotalSupply] = useState('2500')

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeNow(new Date())
    }, 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    const loadData = async () => {
      if (account) {
        try {
          const tokensOwned = await nftContract?.callStatic.balanceOf(account)
          setBalanceOf(tokensOwned || '0')
        } catch (e) {
          console.error(e)
        }
      }
      const maxEle = await nftContract?.callStatic.MAX_ELEMENTS()
      const totSupply = await nftContract?.callStatic.totalSupply()
      setMaxElements(maxEle)
      setTotalSupply(totSupply)
    }
    loadData()
  }, [account, chainId, nftContract, renderKey])

  const handleMint = async () => {
    setState('loading')
    try {
      const price = await nftContract?.callStatic.price(1)
      const tx = await nftContract.mint(1, { value: price })
      const message = await tx.wait(1)
      setState('success')
      toastSuccess('Mint Successful!')
    } catch (e) {
      setState('error')
      toastError(
        'Error',
        'Please try again. Confirm the transaction and make sure you are paying enough gas!'
      )
    }
    setRenderKey(Math.random())
  }

  const timeDifference = LAUNCH_TIME - timeNow
  return (
    <Container>
      <CardNav />
      <AppBody>
        <Wrapper id="swap-page">
          <NftPageHeader
            title="Mint"
            description="Mint to get your own personal KING SHIBAs"
          />
          <CardBody style={{ textAlign: 'center' }}>
            <div style={{ marginBottom: 10 }}>
              Collection of KING SHIBA NFTs (2500 KINGS)
              <br />
            </div>
            <ImageFlasher parentRerenders/>
            <AutoColumn gap="md" />
            <BottomGrouping>
              {timeDifference > 0 ? (
                <>
                  Launching in{' '}
                  <CountdownTimer endTime={LAUNCH_TIME} timeNow={timeNow} />
                </>
              ) : account ? (
                <>
                <Btn onClick={handleMint} disabled={state === 'loading'}>
                  {state === 'loading' ? 'Pending...' : 'Mint Your King'}
                </Btn>
                <br/>
                0.3 BNB per King
                </>
                // <Btn onClick={handleMint} disabled>
                //   {state === 'loading' ? 'Pending...' : 'SOLD OUT'}
                // </Btn>
              ) : (
                <Btn>Connect Wallet To Mint</Btn>
              )}
            </BottomGrouping>
            <br />
            {timeDifference < -10000 &&
              `${maxElements - totalSupply} out of ${maxElements} remaining`}
          </CardBody>
        </Wrapper>
      </AppBody>
      <GlowHeading>
        {' '}
        {balanceOf !== '0' && `${balanceOf} King Shiba In Your Wallet`}{' '}
      </GlowHeading>
    </Container>
  )
}

export default MintNfts
