import React, { useState, useMemo, useEffect } from 'react'
import { useActiveWeb3React } from 'hooks'
import { useContract } from 'hooks/useContract'
import { Link } from 'react-router-dom'
import Pagination from 'rc-pagination'
import styled from 'styled-components'
import queryString from 'query-string'
import { ArrowLeft, ArrowRight } from 'react-feather'
import { Card, Button } from '@pancakeswap-libs/uikit'
import Container from 'components/Container'
import CardNav from 'components/CardNav'
import NftCard from 'components/NftCard'
import { marketAddressByChain } from 'config'
import { getNumber } from 'utils/formatBalance'
import {
  GlowHeading as Heading,
  GlowSubHeading as SubHeading,
} from 'components/GlowHeading'
import * as NftMarketplace from 'config/abis/NftMarketplace.json'
import { useLocation, useHistory } from 'react-router-dom'
import NftPageHeader from 'components/NftPageHeader'
import filterAuctions from './utils'
import './pagination.css'

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  justify-content: center;
`

export const CardWrapper = styled(Card)`
  position: relative;
  @media only screen and (max-width: 780px) {
    width: 100%;
  }
  width: 450px;
  max-width: 100%;
  z-index: 5;
  background-color: rgba(1, 1, 1, 0.6);
  border: 2px solid rgb(244, 122, 8);
  box-shadow: rgb(221 82 0) 0px 0px 1.5rem,
    rgb(221 82 0 / 70%) 0px 0px 1rem 0px inset;
  margin: auto;
  overflow: visible;
`

const FlexElement = styled.div`
  padding: 10px;
  flex: 1;
  justify-content: center;
`

const FETCH_LIMIT = 1000
const PER_PAGE = 15

const NftMarket = ({ match: { path } }) => {
  const { account, chainId } = useActiveWeb3React()
  const showUserListings = path === '/nftmylistings' && account

  const { pathname, search } = useLocation()
  const history = useHistory()
  const { priceMin, priceMax, sort, page = 1 } = queryString.parse(search)

  const marketAddress = marketAddressByChain[chainId || '56']
  const marketContract = useContract(marketAddress, NftMarketplace.abi, true)

  const [loading, setLoading] = useState(true)
  const [aucs, setAucs] = useState([])

  useEffect(() => {
    const loadData = async () => {
      setLoading(true)
      try {
        if (showUserListings) {
          const auctionStructs =
            await marketContract?.callStatic.getListedNftsOfUser(account)
          const auctionIds = auctionStructs.map((as) => Number(as.auctionId))
          const auctions = await marketContract?.callStatic.getAuctions(
            auctionIds
          )
          setAucs(auctions)
        } else {
          const { auctions } = await marketContract?.callStatic.fetchAuctions(
            0,
            FETCH_LIMIT
          )
          const auctionsCount =
            await marketContract.callStatic.getAuctionsCount()
          setAucs(auctions)
        }
        setLoading(false)
      } catch (e) {
        console.log('error is', e)
        setLoading(false)
      }
    }
    loadData()
  }, [account, chainId, marketContract, showUserListings])

  const filteredAuctions = filterAuctions(aucs, {
    priceMin,
    priceMax,
    sort,
  })
  const aucsToDisplay = filteredAuctions.slice(
    (page - 1) * PER_PAGE,
    page * PER_PAGE
  )
  const totalPages = Math.ceil(filteredAuctions.length / PER_PAGE)

  const onChangePage = (newPage) => {
    const searchQuery = queryString.stringify({
      page: newPage,
      priceMin,
      priceMax,
      sort
    })
    history.replace({
      search: searchQuery,
    })
  }
  const clearFilters = () => {
    history.replace({
      search: '',
    })
  }
  return (
    <Container>
      <Heading>
        {showUserListings ? 'Your Listings' : 'Live NFTs for sale'}
      </Heading>
      <CardNav />
      <CardWrapper style={{ zIndex: 100 }}>
        <NftPageHeader
          title="Marketplace"
          description={
            showUserListings ? 'Your Live Auctions' : 'Public Live Auctions'
          }
        />
      </CardWrapper>
      <hr />
      <FlexContainer>
        {loading ? (
          'Loading...'
        ) : aucsToDisplay.length > 0 ? (
          <>
            {aucsToDisplay.map((auc) => {
              const {
                tokenContract,
                reservePrice,
                amount,
                tokenId,
                firstBidTime,
                duration,
              } = auc
              const endTime =
                new Date(firstBidTime * 1000).getTime() +
                Number(duration) * 1000
              const floorPrice = Math.max(
                getNumber(reservePrice),
                getNumber(amount)
              )
              return (
                <NftCard
                  key={tokenContract + tokenId.toString()}
                  contract={tokenContract}
                  id={Number(tokenId)}
                  endTime={Number(firstBidTime) && new Date(endTime)}
                  price={floorPrice}
                />
              )
            })}
          </>
        ) : (
          <SubHeading>
            {showUserListings ? (
              'You have no active listings'
            ) : (
              <div>No Auctions Found</div>
            )}
          </SubHeading>
        )}
      </FlexContainer>
      );
      {(priceMin || priceMax || sort || page) && (
        <Button variant="text" onClick={clearFilters}>
          Not seeing results? Try clearing filters.
        </Button>
      )}
      {totalPages > 1 && (
        <div style={{ lineHeight: '25px' }}>
          <Pagination
            className="ant-pagination"
            pageSize={15}
            current={page}
            total={filteredAuctions.length}
            onChange={onChangePage}
          />
        </div>
      )}
      <br />
      <br />
      <br />
      <br />
      <Link to="/nftwallet">
        <Button>Create Listing</Button>
      </Link>
    </Container>
  )
}

export default NftMarket

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.colors.text};
  fill: none !important;
  cursor: pointer;
`
const StyledArrowRight = styled(ArrowRight)`
  color: ${({ theme }) => theme.colors.text};
  fill: none !important;
  cursor: pointer;
`
const PageLabel = styled.span`
  color: white;
  vertical-align: top;
  padding: 0 10px;
`