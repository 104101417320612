import React, { ReactNode, useState } from 'react'
import { useActiveWeb3React } from 'hooks'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  Heading,
  IconButton,
  Text,
  Flex,
  useModal,
} from '@pancakeswap-libs/uikit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faWallet,
  faChevronDown,
  faFilter,
} from '@fortawesome/free-solid-svg-icons'

import Dropdown from 'react-simple-dropdown'
import FilterModal from './FilterModal'

const DropdownTrigger = Dropdown.DropdownTrigger
const DropdownContent = Dropdown.DropdownContent

interface PageHeaderProps {
  title: ReactNode
  description?: ReactNode
  children?: ReactNode
}

const StyledPageHeader = styled.div`
  padding: 24px;
  overflow: visible;
  z-index: 100;
`

const Details = styled.div`
  flex: 1;
`

const NavbarDropdown = styled.div`
  position: relative;
  display: inline-block;
  z-index: 100;
  &:hover {
    display: block;
    > div {
      display: block;
    }
  }
`

const NavbarDropdownContent = styled.div`
  display: none;
  position: absolute;
  min-width: 140px;
  padding: 12px;
  z-index: 50;
  transform: translateX(calc(-50% - 12px));
  background: rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.invertedContrast};
  text-align: right;
`

const WalletButton = styled(IconButton)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60px;
  padding: 2px 10px;
  margin:0px 5px;
`

const PageHeader = ({ title = '', description, children }: PageHeaderProps) => {
  const [showModal, setShowModal] = useState(false)
  const { account } = useActiveWeb3React()
  const onDismiss = async () => {
    setShowModal(false)
  }
  const [onPresentFilter] = useModal(<FilterModal onDismiss={onDismiss} />)
  return (
    <StyledPageHeader>
      <Flex alignItems="center">
        <Details>
          <Heading mb="8px">{title}</Heading>
          {description && (
            <Text color="textSubtle" fontSize="14px">
              {description}
            </Text>
          )}
        </Details>
        <WalletButton
          style={{ justifyContent: 'center' }}
          variant="secondary"
          title="NFT Wallet"
          onClick={onPresentFilter}
        >
          <FontAwesomeIcon icon={faFilter} />
        </WalletButton>
        {account && (
          <NavbarDropdown>
            <WalletButton variant="secondary" title="NFT Wallet">
              <FontAwesomeIcon icon={faWallet} />
              <FontAwesomeIcon icon={faChevronDown} />
            </WalletButton>
            <NavbarDropdownContent>
              <Link to="/nftwallet">My Holdings</Link>
              <hr />
              <Link to="/nftmylistings">My Listings</Link>
            </NavbarDropdownContent>
          </NavbarDropdown>
        )}
      </Flex>
      {children && <Text mt="16px">{children}</Text>}
    </StyledPageHeader>
  )
}

export default PageHeader