import BigNumber from 'bignumber.js'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Modal, LinkExternal, Text } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import { MouseoverTooltip } from 'components/Tooltip'
import ModalActions from 'components/ModalActions'
import ModalInput from 'components/ModalInput'
import { getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance'
import useToast from 'hooks/useToast'

interface ListingModalProps {
  onDismiss: () => void
  dismiss: () => void
  editMode: boolean
  tokenId: string
  tokenName: string
  tokenContract: any
  marketContract: any
}

const CustomButton = styled(Button)`
  background-color: ${(props) =>
    props.variant === 'secondary' ? 'white' : 'rgb(244, 122, 8)'};
  color: ${(props) =>
    props.variant === 'secondary' ? 'rgb(244, 122, 8)' : 'white'};
  border: ${(props) =>
    props.variant === 'secondary' ? '1px solid rgb(244, 122, 8)' : 'none'};
`
const LittleButton = styled(CustomButton)`
  background-color: rgb(173, 57, 67);
  height: 30px;
  font-size: 12px;
`
const CustomLinkExternal = styled(LinkExternal)`
  color: rgba(163, 81, 5, 1);
`

const ListingModal: React.FC<ListingModalProps> = ({
  onDismiss,
  dismiss,
  editMode,
  tokenId,
  tokenName,
  tokenContract,
  marketContract,
}) => {
  const [tokenApprovePending, setTokenApprovePending] = useState(false)
  const [tokenApproved, setTokenEnabled] = useState(false)
  const [reserveVal, setReserveVal] = useState('0')
  const [buyNowVal, setBuyNowVal] = useState('0')
  const [pendingTx, setPendingTx] = useState(false)
  const { toastSuccess, toastError } = useToast()

  // check for approval
  useEffect(() => {
    const loadData = async () => {
      try {
        const marketAllowance = await tokenContract.callStatic.getApproved(
          tokenId
        )
        setTokenEnabled(marketAllowance === marketContract.address)
      } catch (e) {
        console.error(e)
      }
    }
    loadData()
  }, [tokenContract, marketContract, tokenId])

  const handleBuyNowChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget.validity.valid) {
        setBuyNowVal(e.currentTarget.value.replace(/,/g, '.'))
      }
    },
    [setBuyNowVal]
  )

  const handleReserveChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget.validity.valid) {
        setReserveVal(e.currentTarget.value.replace(/,/g, '.'))
      }
    },
    [setReserveVal]
  )

  const onListToken = async (reservePrice, buyNowPrice) => {
    const duration = 60 * 60 * 24 * 3 // default 3 days
    const currency = '0x0000000000000000000000000000000000000000'
    try {
      if (editMode) {
        const auctionId = await marketContract.callStatic.auctionIdOfToken(
          tokenContract.address,
          tokenId
        )
        const tx = await marketContract.setAuctionReservePrice(
          auctionId,
          reservePrice.toString(),
          buyNowPrice.toString()
        )
        await tx.wait(1)
        toastSuccess('Successfully edited listing.')
      } else {
        const tx = await marketContract.createAuction(
          tokenId,
          tokenContract.address,
          duration,
          reservePrice.toString(),
          currency,
          buyNowPrice.toString(),
          { value: '10000000000000000' }
        )
        await tx.wait(1)
        toastSuccess('Successfully listed for sale.')
      }
    } catch (e) {
      toastError('Error', 'Please try again and confirm the transaction.')
    }
  }

  const onCancelAuction = async () => {
    try {
      const auctionId = await marketContract.callStatic.auctionIdOfToken(
        tokenContract.address,
        tokenId
      )
      const tx = await marketContract.cancelAuction(auctionId)
      await tx.wait(1)
      toastSuccess('Successfully cancelled auction')
    } catch (e) {
      toastError('Error', String(e))
    }
  }

  const onEnable = async () => {
    setTokenApprovePending(true)
    try {
      const isEnabled = await tokenContract.approve(
        marketContract.address,
        tokenId
      )
      await isEnabled.wait(1)
      toastSuccess('Contract Enabled', 'You can now list this for sale!')
      setTokenEnabled(true)
    } catch (e) {
      toastError(
        'Error',
        'Please try again. Confirm the transaction and make sure you are paying enough gas!'
      )
    }
    setTokenApprovePending(false)
  }

  const reserveValNumber = new BigNumber(Number(reserveVal) * 1e18)
  const buyNowValNumber = new BigNumber(Number(buyNowVal) * 1e18)
  return (
    <Modal title={tokenName} onDismiss={onDismiss}>
      {tokenApproved || editMode ? (
        <>
          <Text>
            Listings are auction style and last for 3 days.
            <br />
            You must set a starting price to list this NFT for sale.
            <br />
          </Text>
          <br />
          <br />
          <div style={{ display: 'flex' }}>
            <ModalInput
              value={reserveVal}
              allowZeroBalance
              onChange={handleReserveChange}
              symbol="BNB"
              inputTitle="Starting Bid Price"
              decimals={18}
            />
            <div style={{ width: '20px' }} />
            <ModalInput
              allowZeroBalance
              value={buyNowVal}
              onChange={handleBuyNowChange}
              symbol="BNB"
              inputTitle="Buy Now Price"
              decimals={18}
            />
          </div>
          <br />
          <Text fontSize="13px">
            * Listing fee of 0.01 BNB and 5% on sale applies
            <br />* If specifying a buy price, it must be higher than the
            reserve price.
          </Text>
          <ModalActions>
            <CustomButton
              variant="subtle"
              onClick={onDismiss}
              width="100%"
              disabled={pendingTx}
            >
              Close
            </CustomButton>
            <CustomButton
              width="100%"
              disabled={
                pendingTx ||
                !reserveValNumber.isFinite() ||
                reserveValNumber.eq(0)
              }
              onClick={async () => {
                setPendingTx(true)
                await onListToken(reserveValNumber, buyNowValNumber)
                setPendingTx(false)
                onDismiss()
                dismiss()
              }}
            >
              {pendingTx ? 'Pending Confirmation' : 'Confirm'}
            </CustomButton>
          </ModalActions>
          {editMode && (
            <LittleButton
              variant="danger"
              onClick={async () => {
                setPendingTx(true)
                await onCancelAuction()
                setPendingTx(false)
                onDismiss()
                dismiss()
              }}
              width="100%"
              disabled={pendingTx}
            >
              Cancel Auction
            </LittleButton>
          )}
        </>
      ) : (
        <>
          <CustomButton
            onClick={onEnable}
            width="100%"
            disabled={tokenApprovePending}
          >
            {tokenApprovePending ? 'Pending...' : 'Enable Listing'}
          </CustomButton>
        </>
      )}
    </Modal>
  )
}

export default ListingModal
