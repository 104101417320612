import React, { useEffect, useState } from 'react'

const IMAGES = [
  '/1.png',
  '/2.png',
  '/3.png',
  '/4.png',
  '/5.png',
  '/6.png',
  '/7.png',
  '/8.png',
  '/9.png',
  '/10.png',
  '/11.png',
  '/12.png',
  '/13.png',
  '/14.png',
  '/15.png',
  '/16.png',
  '/17.png',
  '/18.png',
  '/19.png',
  '/20.png',
  '/21.png',
  '/22.png',
  '/23.png',
  '/24.png',
  '/25.png',
]
const ImageFlasher = () => {
  const [imageIndex, setImageIndex] = useState(0)
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomIndex = Math.random() * 25
      setImageIndex(randomIndex)
    }, 300)
    return () => {
      clearInterval(intervalId)
    }
  }, [])
  const key = Math.floor(imageIndex % 26)

  return (
    <div>
      <img
        alt="nft"
        src={`${process.env.REACT_APP_PUBLIC_URL}/images/nftsamples${
          IMAGES[key]
        }`}
      />
    </div>
  )
}

export default ImageFlasher
