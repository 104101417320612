import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useWalletModal, Button } from '@pancakeswap-libs/uikit'
import { useWeb3React } from '@web3-react/core'
import useAuth from 'hooks/useAuth'
import styled from 'styled-components'
import GetPrice from 'utils/getPrice'
import { formatNumber } from 'utils/formatBalance'
import './style.css'

const StarsBG = styled(({className}) => (
  <video className={className} autoPlay loop muted poster="https://static.wixstatic.com/media/df6cf8_c87009461fe04666b7039d17177e265df000.jpg/v1/fill/w_1118,h_914,al_c,q_85,usm_0.33_1.00_0.00/df6cf8_c87009461fe04666b7039d17177e265df000.webp">
    <source src="https://video.wixstatic.com/video/df6cf8_c87009461fe04666b7039d17177e265d/1080p/mp4/file.mp4" type="video/mp4"/>
  </video>
  ))`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center center;
`

const LogoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex: 1;
`

const Logo = styled.img`
  height: 60px;
  width: 60px;
`

const ValueText = styled.div`
  margin-left: 10px;
  color: ${props => props.theme.colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-width: 986px) {
    top: 0;
    width: max-content;
    width: -webkit-max-content;
  }
`

const TitleText = styled.div`
  padding-top: 5px;
  font-size: 32px;
  flex: 1;
  text-align: center;
  color: white;
  @media only screen and (max-width: 780px) {
    margin: 10px 0;
  }
`

const TitleTextBold = styled.div`
  font-weight: 600;
  display: contents;
  filter: drop-shadow(30px 10px 4px #4444dd);
`

const ConnectBtnContainer = styled.div`
  flex: 1;
  text-align: right;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 0;
  background-color: rgba(1, 1, 1, 0.4);
  border-top: 1px solid darkgrey;
`

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: rgba(255,255,255,0.7);
`

const Menu: React.FC = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const location = useLocation()
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(
    login,
    logout,
    account || undefined
  )
  const [mainTokenPrice, setMainTokenPrice] = useState('')
  const { pathname } = location
  const { children } = props

  async function getPrice() {
    try {
      const rewardTokenClass = new GetPrice()
      await rewardTokenClass.init('rewardToken')
      const {
        pairPriceA: esbbPrice,
        pairPriceB: bbprice,
        price: esprice,
      } = await rewardTokenClass.getLatestPrice()
      setMainTokenPrice(formatNumber(esprice.toNumber(), 4, 4))
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      getPrice()
    }, 10000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])
  return (
    <div className="egg-menu-container">
      {/* title bar */}
      <div className="title-bar">
        <div className="container content">
          {/* logo */}
          <LogoContainer>
            <Logo
              src={`${process.env.REACT_APP_PUBLIC_URL}/images/logo-circle.png`}
            />
            <ValueText>
              1 KINGSHIB = {mainTokenPrice ? `$${mainTokenPrice}` : 'loading...'}
            </ValueText>
          </LogoContainer>
          {/* title */}
          <TitleText className="title-text">
            <TitleTextBold>KING SHIBA</TitleTextBold> NFTs
          </TitleText>
          {/* connect */}
          <ConnectBtnContainer>
            <Button
              onClick={account ? onPresentAccountModal : onPresentConnectModal}
            >
              {account ? 'Connected' : 'Connect to a wallet'}
            </Button>
          </ConnectBtnContainer>
        </div>
      </div>

      {/* menu bar */}
      <div className="menu-bar">
        <span style={{ fontSize: 12, color: '#2e2e2e', position: 'absolute' }}>
          v3.5
        </span>
        <div className="container content">
          { /*
          <Link
            to="/swap"
            className={`menu-item ${location.pathname === '/swap' && 'active'}`}
          >
            Swap
          </Link>
          <Link
            to="/pool"
            className={`menu-item ${location.pathname === '/pool' && 'active'}`}
          >
            Pool
          </Link>
          <Link
            to="/farms"
            className={`menu-item ${
              location.pathname === '/farms' && 'active'
            }`}
          >
            Stake
          </Link>
          */ }
          <Link
            to="/mintnfts"
            className={`menu-item ${
              location.pathname.includes('mint') && 'active'
            }`}
          >
            Mint
          </Link>
          <Link
            to="/nftmarket"
            className={`menu-item ${
              location.pathname.includes('market') && 'active'
            }`}
          >
            Marketplace
          </Link>
          <Link
            to="/nftwallet"
            className={`menu-item ${
              location.pathname.includes('wallet') && 'active'
            }`}
          >
            Wallet
          </Link>
        </div>
      </div>

      {/* content */}
      <div className="main-content">
        <StarsBG />

        {children}
      </div>

      {/* footer */}
      <Footer>
        <FooterContent
          className="container"
          style={{ justifyContent: 'space-between', opacity: 0.8 }}
        >
          <div>
            This dApp was built with ❤ by the wizards at{' '}
            <a style={{color: 'light-blue'}}target="_blank" rel="noreferrer" href="https://highstack.co">
              Highstack.co
            </a>
          </div>
          <div style={{ textAlign: 'right' }}>
            *King Shiba makes no promises and is not responsible for any
            losses or errors. Use at your own risk.
          </div>
        </FooterContent>
      </Footer>
    </div>
  )
}

export default Menu
