import React, { useState, useEffect } from 'react'
import { useActiveWeb3React } from 'hooks'
import { useContract } from 'hooks/useContract'
import { RouteComponentProps } from 'react-router'
import useToast from 'hooks/useToast'
import styled from 'styled-components'
import { Card, CardBody, Button } from '@pancakeswap-libs/uikit'
import CountdownTimer from 'components/CountdownTimer'
import Container from 'components/Container'
import { shortenAccount } from 'utils/formatAccount'
import { useModal } from '@pancakeswap-libs/uikit'
import * as GalaxyHeroesNFT from 'config/abis/GalaxyHeroesNFT.json'
import * as NftMarketplace from 'config/abis/NftMarketplace.json'
import { marketAddressByChain } from 'config'
import { getNumber } from 'utils/formatBalance'
import {
  GlowHeading as Heading,
  GlowSubHeading as SubHeading,
} from 'components/GlowHeading'
import { TextBody, TextHeading } from './components'
import ListingModal from './ListingModal'
import BiddingModal from './BiddingModal'

const MARKETPLACE_READY = true

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100vh;
  max-width: 100%;
  justify-content: top;
`
const CardFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  text-transform: capitalize;
  padding: 5px;
`

export const CardWrapper = styled(Card)`
  position: relative;
  @media only screen and (max-width: 780px) {
    width: 100%;
  }
  max-width: 100%;
  z-index: 5;
  background-color: rgba(1, 1, 1, 0.6);
  border: 2px solid rgb(244, 122, 8);
  box-shadow: rgb(221 82 0) 0px 0px 1.5rem,
    rgb(221 82 0 / 70%) 0px 0px 1rem 0px inset;
  margin: auto;
  text-align: left;
`
const ImageCard = styled(CardWrapper)`
  width: 100%;
  max-width: 60vh;
  margin-top: 30px;
`

const FlexElement = styled.div`
  @media only screen and (max-width: 780px) {
    padding: 10px;
  }
  padding: 30px;
  flex: 1;
  justify-content: center;
`
const AttrValue = styled.div`
  flex:2;
  text-align: right
`

export default function NftPdp({
  history,
  match: {
    params: { nftCollectionAddress, tokenId },
  },
}: RouteComponentProps<{ nftCollectionAddress: string; tokenId: string }>) {
  const { account, chainId } = useActiveWeb3React()
  const marketAddress = marketAddressByChain[chainId || '56']
  const nftContract = useContract(
    nftCollectionAddress,
    GalaxyHeroesNFT.abi,
    true
  )
  const marketContract = useContract(marketAddress, NftMarketplace.abi, true)

  // TODO:
  // implement end auction

  const [loading, setLoading] = useState(true)
  const [collectionName, setCollectionName] = useState('')
  const [tokenName, setName] = useState('')
  const [nftOwner, setNftOwner] = useState('')
  const [desc, setDesc] = useState('')
  const [cardMediaType, setMediaType] = useState('')
  const [cardMediaUri, setMediaUri] = useState('')
  const [cardAttributes, setAttributes] = useState([])
  const [retries, setRetries] = useState(0)
  const [collectionAttributes, setCollectionAttributes] = useState({})
  const [auctionId, setAuctionId] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [renderKey, setRenderKey] = useState('0')
  const { toastSuccess, toastError } = useToast()

  // Auction Details
  const [aucApproved, setAucApproved] = useState('')
  const [aucHighestBid, setAucHighestBid] = useState(0)
  const [aucEndTime, setAucEndTime] = useState(0)
  const [aucFirstBidTime, setAucFirstBidTime] = useState(0)
  const [aucReservePrice, setAucReservePrice] = useState(0)
  const [aucTokenOwner, setAucTokenOwner] = useState('')
  const [aucHighestBidder, setAucHighestBidder] = useState('')
  const [aucCurrency, setAucCurrency] = useState('')
  const [aucBuyNowPrice, setAucBuyNowPrice] = useState(0)

  useEffect(() => {
    const loadNftData = async () => {
      try {
        const contractName = await nftContract?.callStatic.name()
        setCollectionName(contractName)
        const tokOwner = await nftContract?.callStatic.ownerOf(tokenId)
        setNftOwner(tokOwner)

        if (tokOwner === marketAddress) {
          const aucId = await marketContract?.callStatic.auctionIdOfToken(
            nftCollectionAddress,
            tokenId
          )
          setAuctionId(aucId)
          const {
            approved,
            amount,
            duration,
            firstBidTime,
            reservePrice,
            tokenOwner,
            bidder,
            auctionCurrency,
            buyNowPrice,
          } = await marketContract?.callStatic.getAuction(aucId)
          const endTime =
            new Date(firstBidTime * 1000).getTime() + Number(duration) * 1000
          setAucApproved(approved)
          setAucFirstBidTime(Number(firstBidTime))
          setAucHighestBid(getNumber(amount))
          setAucEndTime(endTime)
          setAucReservePrice(getNumber(reservePrice))
          setAucTokenOwner(tokenOwner)
          setAucHighestBidder(bidder)
          setAucCurrency(auctionCurrency)
          setAucBuyNowPrice(getNumber(buyNowPrice))
        }

        /* * Get JSON data * */
        let jsonUri = await nftContract?.callStatic.tokenURI(tokenId)
        jsonUri = jsonUri.replace('ipfs://', process.env.REACT_APP_IPFS_GATEWAY)
        const resp = await (await fetch(jsonUri)).json()
        const { name, description, image, attributes } = resp
        const mediaType = image.indexOf('mp4') > 0 ? 'video' : 'image'
        const mediaUri = image.replace(
          'ipfs://',
          process.env.REACT_APP_IPFS_GATEWAY
        )

        /* * Get Collection Metadata * */
        const cid = jsonUri.split('/')[4]
        const metadataUri = `${process.env.REACT_APP_IPFS_GATEWAY}${cid}/_metadata.json`
        const metaDataResp = await (await fetch(metadataUri)).json()
        const collectionAttr = {}
        metaDataResp.forEach((item) => {
          item.attributes.forEach((attribute) => {
            const attrName = attribute.trait_type
            const attrVal = attribute.value
            const collectionAttrCount =
              (collectionAttr[attrName] || {})[attrVal] || 0
            collectionAttr[attrName] = {
              ...(collectionAttr[attrName] || {}),
              [attrVal]: collectionAttrCount + 1,
            }
          })
        })
        // sum totals across each attribute
        Object.keys(collectionAttr).forEach((attr) => {
          collectionAttr[attr].total = (
            Object.values(collectionAttr[attr]) as number[]
          ).reduce((a, b) => a + b, 0)
        })

        setCollectionAttributes(collectionAttr)
        setName(name)
        setAttributes(attributes)
        setDesc(description)
        setMediaUri(mediaUri)
        setMediaType(mediaType)
        setLoading(false)
      } catch (e) {
        if (retries < 5) {
          setRetries(retries + 1)
        }
      }
    }

    loadNftData()
  }, [
    nftContract,
    tokenId,
    retries,
    marketContract,
    marketAddress,
    nftCollectionAddress,
    renderKey,
  ])

  const onDismiss = () => {
    setShowModal(false)
    setRenderKey(`${Math.random() * 10}`)
  }

  const onEndAuction = async () => {
    try {
      setLoading(true)
      const tx = await marketContract?.endAuction(auctionId)
      await tx.wait(1)
      setLoading(false)
      toastSuccess('Successfully finalized auction.')
      setRenderKey(`${Math.random() * 10}`)
    } catch (e) {
      toastError('Error', 'Please try again and confirm the transaction.')
    }
  }

  const [onCreateAuction] = useModal(
    <ListingModal
      editMode={nftOwner === marketAddress}
      onDismiss={onDismiss}
      dismiss={onDismiss}
      tokenName={tokenName}
      tokenContract={nftContract}
      marketContract={marketContract}
      tokenId={tokenId}
    />
  )

  const [onPlaceBid] = useModal(
    <BiddingModal
      onDismiss={onDismiss}
      dismiss={onDismiss}
      tokenName={tokenName}
      marketContract={marketContract}
      tokenId={tokenId}
      auctionId={auctionId}
      floorPrice={Math.max(aucReservePrice, aucHighestBid)}
      buyNowPrice={aucBuyNowPrice}
    />
  )

  const src =
    cardMediaUri || `${process.env.REACT_APP_PUBLIC_URL}/images/new_loading.gif`
  const isForSale = nftOwner === marketAddress

  const totalRarity = cardAttributes.length
  let thisRarity = 0
  let ultraRareBoost = 1

  const floorPrice = Math.max(aucReservePrice, aucHighestBid)

  const auctionEnded = aucFirstBidTime && aucEndTime < new Date().getTime()

  return (
    <Container>
      <Heading> {collectionName} </Heading>
      <SubHeading> {tokenName} </SubHeading>
      <FlexContainer>
        <ImageCard>
          <CardBody style={{ textAlign: 'center' }}>
            {cardMediaType === 'video' ? (
              <>
                {/** @es-ignore */}
                <video
                  autoPlay
                  loop
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                  src={src}
                />
              </>
            ) : (
              <img alt="nft" src={src} />
            )}
          </CardBody>
        </ImageCard>
        <FlexElement>
          <CardWrapper>
            <CardBody style={{ textAlign: 'center' }}>
              <span>
                {!isForSale && `Owned By: ${shortenAccount(nftOwner)}`}
              </span>
              {isForSale && MARKETPLACE_READY ? (
                <>
                  <TextHeading>Price</TextHeading>
                  <SubHeading>{floorPrice} BNB</SubHeading>
                  <TextBody fontSize="12px">
                    {Number(aucHighestBidder)
                      ? `Highest bid by ${shortenAccount(aucHighestBidder)}`
                      : 'No Bids Yet'}
                  </TextBody>
                  <TextHeading>Auction Time Left</TextHeading>
                  <TextBody>
                    {aucFirstBidTime ? (
                      <CountdownTimer endTime={new Date(aucEndTime)} />
                    ) : (
                      'Auction starts when first bid is received.'
                    )}
                  </TextBody>

                  <TextBody textAlign="center" paddingLeft="0px">
                    <br />
                    {auctionEnded ? (
                      <Button disabled={loading} onClick={onEndAuction}>
                        {loading ? 'Loading... ' : 'Finalize Auction'}
                      </Button>
                    ) : aucTokenOwner === account ? (
                      <Button onClick={onCreateAuction}>Modify Listing</Button>
                    ) : (
                      <Button onClick={onPlaceBid}>Place Bid</Button>
                    )}
                    <br />
                    {auctionEnded
                      ? 'SOLD'
                      : Number(aucBuyNowPrice) > 0 &&
                        `Buy Now Price: ${aucBuyNowPrice.toFixed(2)} BNB`}
                  </TextBody>

                  <TextHeading>
                    Listed By{' '}
                    <span style={{ fontWeight: 200 }}>
                      {shortenAccount(aucTokenOwner)}
                    </span>
                  </TextHeading>
                </>
              ) : (
                <SubHeading>
                  <br />
                  {account === nftOwner
                    ? 'Owned By You'
                    : 'Not Currently For Sale'}
                </SubHeading>
              )}
              {!isForSale && account === nftOwner && MARKETPLACE_READY && (
                <div style={{ textAlign: 'center' }}>
                  <br />
                  <Button onClick={onCreateAuction}> List for Sale </Button>
                </div>
              )}
            </CardBody>
          </CardWrapper>
          <br />
          <CardWrapper>
            <CardBody>
              <SubHeading left> Description </SubHeading>
              <br />
              {desc}
              <hr style={{ margin: '30px' }} />
              <SubHeading left> Attributes </SubHeading>
              {cardAttributes.map((attr: any) => {
                const attrName = attr.trait_type.replace(/[^a-zA-Z ]/g, '')
                const value = attr.value.replace(/_/g, ' ')
                const pct =
                  collectionAttributes[attr.trait_type][attr.value] /
                  collectionAttributes[attr.trait_type].total
                thisRarity += pct
                if (attrName === 'animated') {
                  ultraRareBoost += 4
                  if (value.includes('ultrarare')) {
                    ultraRareBoost += 50
                  }
                }
                return (
                  <CardFlex key={attrName}>
                    <div style={{flex:1}}>{attrName}</div>
                    <AttrValue>{`${value} (${(pct * 100).toFixed(
                      1
                    )}%)`}</AttrValue>
                  </CardFlex>
                )
              })}
              <CardFlex>
                <div>
                  <b>Rarity Score:</b>
                </div>
                <div>
                  <b>
                    {totalRarity &&
                      `(${(
                        (thisRarity / totalRarity / ultraRareBoost) *
                        100
                      ).toFixed(1)}%)`}
                  </b>
                </div>
              </CardFlex>
              *Lower rarity % = more rare.
            </CardBody>
          </CardWrapper>
        </FlexElement>
      </FlexContainer>
    </Container>
  )
}
